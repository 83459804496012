import consumer_budget_getBudgetSubsidyList from '@/lib/data-service/haolv-default/consumer_budget_getBudgetSubsidyList';
import Pagination from "@/component/pagination";
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";
export default {
    data() {
        return {
            params: {
                "cityName": "",
                "currentPage": 1,
                "endDate": "",
                "evectionNo": "",
                "keyword": "",
                "orderBy": "",
                "pageSize": 10,
                "startDate": "",
                "userName": "",

                evectionDate: null,
            },
            total: 0,
            tableData: [],
        }
    },
    props: {},
    components: {Pagination},
    created() {
    },
    mounted() {
    },
    async activated() {
        await this.getStaffList();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        async search() {
            this.params.currentPage = 1;
            await this.getStaffList();
        },
        update() {},
        changeEvectionDate(val) {
            if (val) {
                this.params.startDate = val[0];
                this.params.endDate = val[1];
            } else {
                this.params.startDate = '';
                this.params.endDate = '';
            }
        },
        async getStaffList() {
            let [err, res] = await awaitWrap( consumer_budget_getBudgetSubsidyList(this.params));
            if (err) {
                return
            }
            this.tableData = res.datas.list;
            this.total = res.datas.totalPage;
        },
    }
}